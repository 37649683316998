import React from 'react';

function Contact() {
    return (
        <div>
            <h1>Contact Us</h1>
            <p>If you have any questions or need further information, we’re here to help! Please feel free to reach out to us using the contact details below, and our team will get back to you as soon as possible.</p>
            <p><strong>Email:</strong> <a href="mailto:rachelle@sortedapp.eu.org">rachelle@sortedapp.eu.org</a></p>
                      <p><strong>Office Hours:</strong> Monday to Friday, 9:00 AM - 6:00 PM (EST)</p>
            <p>Our team is dedicated to providing you with the support you need, whether you have questions about our courses, need help with enrollment, or simply want to learn more about our services. Don't hesitate to get in touch – we look forward to hearing from you!</p>
           <p>Stay connected with us on social media to get the latest updates, tips, and design inspiration:</p>
        
        </div>
    );
}

export default Contact;
