import React from 'react';

function About() {
    return (
        <div>
            <h1>About Sorted App</h1>
            <p>At Sorted App, we are committed to providing top-notch computer design training. Whether you're looking to learn 2D or 3D design, animation, or any other aspect of digital art, our training will help you excel in the world of design.</p>
            <p>Founded by a team of passionate design professionals, Sorted App was created with the goal of bridging the gap between traditional design education and the ever-evolving demands of the digital world. Our mission is to empower individuals with the skills and knowledge they need to transform their creative ideas into compelling digital experiences. We take pride in offering a diverse range of courses that cater to various skill levels and interests, ensuring that everyone has the opportunity to reach their full potential.</p>
            <p>Our approach to teaching combines rigorous coursework with hands-on projects, allowing students to apply what they learn in real-world scenarios. We believe that learning should be an engaging and interactive process, which is why our curriculum is designed to be both challenging and enjoyable. By incorporating the latest industry trends and technologies, we ensure that our students are not only proficient in current design practices but also prepared for future advancements in the field.</p>
            <p>At Sorted App, we value the success of our students above all else. Our dedicated support team is always available to provide guidance and answer any questions that may arise throughout your learning journey. We are more than just a training provider; we are a community of creatives who are here to support you as you build your career in design. Discover how Sorted App can help you turn your passion for design into a successful and fulfilling profession.</p>
        </div>
    );
}

export default About;
