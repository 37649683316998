import React from 'react';

function Home() {
    return (
        <div>
            <h1>Welcome to Sorted App</h1>
            <p>Join our Computer Designing Training program and unlock your creativity. Our expert-led courses will take you from beginner to professional designer.</p>
            <p>At Sorted App, we believe that great design starts with a solid foundation. Our comprehensive training programs cover everything from the basics of software design to advanced techniques used by industry professionals. Whether you’re just starting out or looking to enhance your skills, our curriculum is tailored to meet your needs and help you succeed.</p>
            <p>Our team of experienced instructors brings real-world expertise to the classroom, providing hands-on learning experiences that bridge the gap between theory and practice. With a focus on the latest tools and technologies, we ensure that you’re equipped with the skills and knowledge needed to thrive in today’s competitive design landscape. Explore our course offerings and take the first step toward mastering the art and science of software design.</p>
            <p>Ready to start your journey? Contact us today to learn more about our programs and how we can help you achieve your design goals. Whether you’re aiming for a career in software design or simply looking to enhance your current skills, Sorted App is here to support you every step of the way. Let’s design your future together!</p>
        </div>
    );
}

export default Home;
